import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1024.000000 1024.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1024.000000) scale(0.100000,-0.100000)">

<path d="M1680 5987 c-13 -7 -35 -28 -48 -47 -35 -52 -26 -113 22 -157 30 -28
43 -33 85 -33 119 0 171 132 86 217 -27 27 -40 33 -76 33 -24 0 -55 -6 -69
-13z"/>
<path d="M2111 5987 c-72 -25 -101 -115 -59 -177 13 -19 36 -41 50 -48 20 -9
170 -12 675 -10 l648 3 -75 54 c-100 71 -220 127 -327 152 -49 11 -96 25 -105
30 -27 14 -765 11 -807 -4z"/>
<path d="M1673 5480 c-57 -35 -77 -98 -49 -163 38 -93 180 -97 226 -6 24 47
25 71 3 116 -32 68 -115 93 -180 53z"/>
<path d="M2100 5477 c-13 -7 -35 -28 -48 -47 -27 -40 -26 -92 3 -136 36 -55
24 -54 815 -54 l730 0 0 85 0 85 30 0 c33 0 37 13 18 55 l-11 25 -756 0 c-585
0 -762 -3 -781 -13z"/>
<path d="M8399 5453 l-24 -28 30 25 c17 14 32 26 34 28 2 1 0 2 -6 2 -5 0 -20
-12 -34 -27z"/>
<path d="M8515 5450 c10 -11 20 -20 22 -20 2 0 -2 9 -9 20 -7 11 -17 20 -22
20 -5 0 -1 -9 9 -20z"/>
<path d="M8431 5393 c1 -60 2 -65 12 -40 13 32 23 34 32 7 3 -11 11 -20 16
-20 12 0 11 7 -1 30 -7 13 -7 21 0 25 17 10 11 44 -9 55 -44 23 -51 14 -50
-57z m44 27 c0 -7 -6 -15 -12 -17 -8 -3 -13 4 -13 17 0 13 5 20 13 18 6 -3 12
-11 12 -18z"/>
<path d="M8372 5395 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M8540 5392 c0 -31 -15 -54 -45 -70 -11 -6 -14 -12 -7 -12 24 0 62 48
62 80 0 16 -2 30 -5 30 -3 0 -5 -13 -5 -28z"/>
<path d="M3857 5303 l-117 -108 0 -76 0 -75 114 -107 114 -107 86 0 86 0 -83
78 c-45 42 -113 107 -152 145 l-70 67 50 48 c28 26 97 91 155 145 l104 97 -85
0 -86 0 -116 -107z"/>
<path d="M5630 5120 l0 -290 65 0 65 0 0 290 0 290 -65 0 -65 0 0 -290z"/>
<path d="M5830 5120 l0 -290 66 0 c65 0 66 0 64 28 -1 15 -2 42 -1 60 l1 33
81 -3 81 -3 23 -55 23 -55 71 -3 70 -3 -31 76 -31 76 38 37 c47 46 65 87 65
150 0 85 -11 114 -59 162 -68 67 -117 80 -307 80 l-154 0 0 -290z m302 168
c55 -16 88 -59 88 -112 0 -83 -51 -116 -180 -116 l-80 0 0 120 0 120 66 0 c37
0 84 -5 106 -12z"/>
<path d="M6430 5120 l0 -292 173 4 c145 4 180 8 224 25 123 49 185 135 185
258 0 134 -57 217 -184 264 -55 21 -84 25 -230 29 l-168 4 0 -292z m370 149
c58 -32 82 -67 88 -127 12 -134 -71 -202 -248 -202 l-90 0 0 181 0 182 103 -5
c85 -4 111 -9 147 -29z"/>
<path d="M7549 5400 c-1 -5 -1 -17 -1 -25 0 -8 0 -28 1 -45 l1 -30 95 0 95 0
2 -232 3 -233 63 -3 62 -3 0 236 0 235 100 0 100 0 0 55 0 55 -260 0 c-166 0
-260 -4 -261 -10z"/>
<path d="M4492 5313 c-27 -49 -98 -173 -157 -278 -59 -104 -109 -193 -112
-197 -2 -5 30 -8 72 -8 l76 0 17 40 17 40 141 0 142 0 16 -40 16 -40 76 0 c42
0 74 4 72 8 -10 25 -312 553 -318 557 -5 3 -31 -34 -58 -82z m93 -206 c19 -35
35 -67 35 -70 0 -4 -34 -7 -76 -7 l-75 0 37 70 c21 39 39 70 41 70 1 0 18 -29
38 -63z"/>
<path d="M4950 5117 l0 -287 65 0 64 0 3 107 3 106 64 -84 c35 -46 73 -94 84
-106 l19 -22 57 72 c31 40 68 88 81 107 l25 35 5 -105 5 -105 67 -3 67 -3 0
268 c1 147 -3 274 -8 281 -6 10 -17 3 -43 -30 -19 -23 -83 -105 -143 -181 -60
-75 -112 -136 -116 -135 -4 2 -73 86 -153 187 l-146 184 0 -286z"/>
<path d="M7263 5302 c-29 -53 -80 -144 -114 -202 -70 -120 -149 -261 -149
-266 0 -2 32 -4 71 -4 l70 0 17 40 17 40 145 -2 145 -3 18 -37 17 -38 70 0
c39 0 70 2 70 4 0 3 -99 179 -237 421 -18 33 -43 79 -55 103 -12 23 -24 42
-27 42 -4 0 -30 -44 -58 -98z m100 -198 c20 -36 37 -70 37 -75 0 -5 -36 -9
-80 -9 -44 0 -80 3 -80 6 0 10 75 144 80 144 3 0 22 -30 43 -66z"/>
<path d="M8290 5394 c0 -5 -17 -39 -38 -75 -72 -121 -272 -476 -272 -483 0 -3
32 -6 70 -6 l70 0 17 40 18 40 142 0 143 0 20 -40 20 -40 70 0 c39 0 70 2 70
5 0 3 -11 22 -23 43 -26 40 -102 174 -150 262 -17 30 -40 71 -52 90 -11 19
-32 56 -45 83 -29 55 -59 97 -60 81z m52 -293 c21 -37 35 -71 31 -75 -8 -7
-147 -8 -155 0 -6 5 73 144 82 144 3 0 22 -31 42 -69z"/>
<path d="M8390 5340 c6 -11 13 -20 16 -20 2 0 0 9 -6 20 -6 11 -13 20 -16 20
-2 0 0 -9 6 -20z"/>
<path d="M1720 4990 c-49 -9 -77 -29 -96 -69 -30 -63 -1 -141 64 -168 70 -29
148 8 173 84 27 83 -52 169 -141 153z"/>
<path d="M2088 4973 c-15 -9 -35 -34 -44 -56 -16 -35 -16 -43 -3 -80 9 -26 26
-50 48 -64 l34 -23 757 0 756 0 17 35 17 35 -35 0 -35 0 0 85 0 85 -742 0
c-676 -1 -745 -2 -770 -17z"/>
<path d="M1669 4467 c-48 -32 -68 -91 -48 -143 42 -110 200 -110 237 1 38 114
-89 210 -189 142z"/>
<path d="M2083 4454 c-64 -50 -61 -146 6 -191 33 -23 38 -23 330 -23 286 0
477 13 593 39 112 26 265 97 356 167 l45 34 -649 0 -649 0 -32 -26z"/>
</g>
</svg>





		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
